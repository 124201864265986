@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';

@import '../node_modules/bootstrap/scss/close';
@import '../node_modules/bootstrap/scss/modal';

//@import "../node_modules/react-select-search/style.css";

@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@import './style/form.scss';
@import './style/relative.scss';

@import 'style/vars.scss';

@import '~react-quill/dist/quill.snow.css';

* {
  transition: none !important;
}

:root {
  --primary-color: #00a2bf;
}

html {
  font-size: 100%;
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-size: 12px !important;
  background-color: #f5f6f8 !important;
}

.content {
  display: flex;
  padding: $outer-paddings;
}

.font-title {
  font-size: 20px;
  font-weight: bold;
}

.hr {
  display: block;
  border-top: 1px solid $border-color;
  margin: 10px 0;
}

.inline-block {
  display: inline-block;
}

/*** bootstrap ***/
.modal-header {
  padding: 1rem !important;

  .close {
    font-size: 32px;
    cursor: pointer;
  }
}

.modal-body {
  img:not(.adapt-img) {
    display: block;
    max-width: 100%;
    max-height: 600px;
    margin: auto;

    &:nth-child(n + 2) {
      margin-top: 15px;
    }
  }
}

/***  Header ***/
.header {
  display: flex;
  justify-content: space-between;
  background-color: var(--primary-color);
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
  margin-bottom: 20px;
  color: white;

  .logo {
    margin: 15px;
    display: flex;

    img {
      height: 43px;
      margin: 7px 15px 0 0;
    }

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .big {
        font-size: 2rem;
        line-height: 35px;
      }

      .small {
        font-size: 12px;
      }
    }
  }

  .profile {
    background: var(--primary-color);
    cursor: pointer;

    .over {
      display: flex;
      align-items: center;
      padding: 0 20px;
      height: 100%;
    }

    .user-name {
      margin-left: 10px;
      font-size: 12px;
    }

    .img {
      width: 50px;
      height: 50px;
      background-size: cover;
      border-radius: 50%;

      &.noava {
        background-image: url('/image/noava.jpg');
      }
    }

    .menu {
      display: none;
      list-style: none;
      background: #fff;
      padding: 0;
      margin: 0;
      position: absolute;
      box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11),
        0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06),
        0 7px 70px rgba(90, 97, 105, 0.1);
      border-radius: 0 0 0.35rem 0.35rem;

      li {
        a {
          padding: 15px 30px;
          display: block;
          white-space: nowrap;

          &:hover {
            background-color: #f7f7f7;
          }
        }

        &:last-child {
          a:hover {
            border-radius: 0 0 0.35rem 0.35rem;
          }
        }
      }
    }

    &:hover .menu {
      display: block;
    }
  }
}

/*** Breadcrumbs ***/
.breadcrumbs {
  display: flex;
  align-items: center;
  padding: 5px 15px;

  .div {
    margin: 0 10px;
    line-height: 14px;
  }
}

/*** tooltip ***/
.hint {
  position: absolute;

  .hint-body {
    padding: 15px;
    border-radius: 0.35rem;
    color: #fff;
    background-color: #5c5c5c;
    position: relative;
    left: -50%;
    box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11),
      0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06),
      0 7px 70px rgba(90, 97, 105, 0.1);
  }

  .pointer {
    position: relative;
    left: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #5c5c5c transparent;
  }
}

/*** error window ***/
#errorWindow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000040;
  display: flex;
  align-items: center;
  justify-content: center;

  .body {
    width: 80vw;
    height: 80vh;
    background: #fff;
    box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11),
      0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06),
      0 7px 70px rgba(90, 97, 105, 0.1);
    border-radius: 0.35rem;
    padding: 1rem;

    .title {
      font-size: 1rem;
      line-height: 1rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    textarea {
      height: calc(100% - 2rem);
      width: 100%;
      margin: 0;
      padding: 0;
      border: none;
      outline: none;
    }
  }

  .close {
    position: fixed;
    top: 20px;
    right: 20px;
    color: #fff;
    opacity: 1 !important;
    width: 33px;
    height: 35px;
    cursor: pointer;

    &::after {
      position: absolute;
      content: '|';
      font-weight: bold;
      font-size: 42px;
      transform: rotateZ(45deg);
      margin: -7px 0 0 8px;
    }

    &::before {
      position: absolute;
      content: '|';
      font-weight: bold;
      font-size: 42px;
      transform: rotateZ(-45deg);
      margin: -7px 0 0 1px;
    }
  }
}

/*** modal window ***/
.modal {
  .modal-body {
    .input-group:nth-child(n + 2) {
      margin-top: 10px;
    }

    input[type='radio'],
    input[type='checkbox'] {
      cursor: pointer;
    }

    textarea {
      width: calc(100% - 1.5rem - 2px);
    }
  }
}

.select-search-box {
  width: 300px;
  position: relative;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 4px;
}

.select-search-box--multiple {
  border-radius: 4px;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  overflow: hidden;
}

.select-search-box::after {
  font-family: fontawesome;
  content: '\f078';
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  text-align: center;
  line-height: 50px;
  color: #222f3e;
  z-index: 1;
}

.select-search-box--multiple::after,
.select-search-box--focus::after {
  content: '\f002';
}

.select-search-box__icon--disabled::after {
  content: none !important;
}

.select-search-box--input::after {
  display: none !important;
}

.select-search-box__out {
  display: none;
}

.select-search-box__search {
  display: block;
  width: 100%;
  height: 50px;
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  padding: 0 20px;
  color: #222f3e;
  -webkit-appearance: none;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  cursor: pointer;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 50px;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

input.select-search-box__search {
  line-height: 1;
}

.select-search-box--multiple .select-search-box__search {
  box-shadow: none;
}

.select-search-box--input .select-search-box__search {
  cursor: text;
}

.select-search-box__search:focus {
  cursor: text;
}

.select-search-box__search--placeholder {
  font-style: italic;
  font-weight: normal;
}

.select-search-box input::-webkit-input-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

.select-search-box input::-moz-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

.select-search-box input:-moz-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

.select-search-box input:-ms-input-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

.select-search-box__select {
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  z-index: 100;
  min-height: 49px;
}

.select-search-box--multiple .select-search-box__select {
  display: block;
  position: static;
  border-top: 1px solid #eee;
  border-radius: 0;
  box-shadow: none;
}

.select-search-box__select--display {
  display: block;
}

.select-search-box__option {
  font-size: 16px;
  font-weight: 400;
  color: #616b74;
  padding: 15px 20px;
  border-top: 1px solid #eee;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.select-search-box__option:first-child {
  border-top: none;
}

.select-search-box__option--hover,
.select-search-box__option:hover {
  background: #f4f7fa;
}

.select-search-box__option--selected {
  background: #54a0ff;
  color: #fff;
  border-top-color: #2184ff;
}

.select-search-box__option--selected:hover,
.select-search-box__option--selected.select-search-box__option--hover {
  background: #2184ff;
  color: #fff;
  border-top-color: #2184ff;
}

.select-search-box__group {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  position: relative;
}

.select-search-box__group-header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

.w-25 {
  width: 150px;
}

.main {
  padding: 0 10px;
  &__count-info-card-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;

    @media (min-width: 1100px) {
      justify-content: flex-start;
    }
  }

  &__count-info-card {
    @media (min-width: 1100px) {
      margin-right: 25px !important;
    }
  }

  &__heading {
    margin-bottom: 5px;
  }

  &__content {
    flex-wrap: wrap;
    display: flex;
    align-items: flex-start;
  }

  &__content-left {
    width: 100%;

    @media (min-width: 736px) {
      width: 55%;
    }
  }
  &__content-right {
    width: 100%;

    @media (min-width: 736px) {
      width: 45%;
    }
  }

  &__table {
    width: 100%;
    text-align: left;
    margin-bottom: 15px;
    th {
      padding-left: 0 !important;
    }
    td {
      padding-left: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.count-info-card {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 736px) {
    width: 45%;
  }

  @media (min-width: 1100px) {
    width: 20%;
  }

  &__header {
    background: #84c2ff;
    padding: 15px 20px;
    margin-bottom: 5px;
    color: #fff;
    font-weight: 900;
    display: flex;
    align-items: flex-end;
    span {
      font-size: 25px;
      margin-right: 5px;
      line-height: 1;
    }
    p {
      font-size: 15px;
      margin: 0;
      padding: 0;
    }
  }
  &__body {
    background: #84c2ff;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }
  &__body-left {
    font-weight: 500;
    display: flex;
    align-items: flex-start;
    width: 50%;
    span {
      font-size: 50px;
      line-height: 1;
    }
    span:first-child {
      font-size: 90px;
      line-height: 1;
      margin-right: 5px;
      font-weight: 900;
    }
  }
  &__body-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50%;

    span {
      display: block;
      margin-bottom: 8px;
      width: 40px;
      height: 40px;
      border-radius: 25px;

      &.bg-red {
        background: #fd0100;
      }

      &.bg-yellow {
        background: #ffe47b;
      }

      &.bg-green {
        background: #08cb17;
      }
    }
    p {
      font-weight: 700;
      font-size: 18px;

      margin: 0;
      padding: 0;
    }
  }
}
