@import "./vars.scss";

.home {
    width: 100%;
    input[type="text"],
    input[type="password"],
    input[type="number"],
    select {
        background: white !important;
    }

    h5 {
        white-space: nowrap;
    }

    .small-input {
        width: 30px;
    }

    .middle-input {
        width: 50px;
    }

    .big-input {
        width: 400px;
    }

    .sidebar-left {
        .area-bg-gray {
            margin-bottom: 20px;
            label {
                margin: 0 !important;
                padding-bottom: 10px;
            }
        }
    }

    .sidebar-right {
        .input-group {
            flex-direction: column;
            label {
                margin: 0 !important;
                padding-bottom: 10px;
            }
        }
    }

    .center {
        position: relative;
        .input-group {
            label {
                margin: 0 20px 0 0 !important;
                .input-address{
                    width: 300px !important;
                }
            }
        }
        &:after {
            content: '';
            display: block;
            height: 99%;
            width: 1px;
            background-color: #cfcfcf;
            position: absolute;
            left: 10px;
            bottom: 0;
        }
    }

    form.as-row {
        padding-top: 20px;
        .sidebar-right,
        .center {
            margin-left: 0;
            padding-left: 30px;
        }

    }

    .area-bg-gray {
        background-color: #fafafa;
        border: 1px solid #cfcfcf;
        margin-bottom: 15px;
        padding: 10px;
    }

    .input-group {
        flex-wrap: wrap;
    }

    .menu {
        a {
            display: inline-block;
            text-decoration: none!important;

            &:first-child {
                border-bottom-left-radius: 0!important;
            }

            &:last-child {
                border-bottom-right-radius: 0!important;
            }
        }
    }

    .container {
        border-radius: 0 .35rem .35rem .35rem;
        border: none;
        margin: -1px 0 0 0;
        padding: $inner-paddings;
        background-color: #fff;
        box-shadow: $box-shadow;
        
        .row {
            &>* {
                margin: 10px 0;
            }
        }
    }

    .table-state > div > div {
        width: 20px;
        height: 12px;
        border: 1px solid $border-color;
        display: inline-block;
    }

    .notes {
        .list {
            display: flex;
            margin: 0 -10px;
            flex-wrap: wrap;

            & > div {
                width: 200px;
                height: 150px;
                border: 1px solid #dee2e6;
                display: inline-block;
                margin: 10px;
                border-radius: .35rem;
                box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);

                .control {
                    display: flex;
                    justify-content: flex-end;
                    padding: 0 5px;
                    height: 25px;

                    .delete {
                        font-size: 16px;
                    }

                    & > * {
                        padding: 0 5px;
                        cursor: pointer;
                        color: #707070;

                        &:hover {
                            color: #000000;
                        }
                    }
                }

                .value {
                    height: calc(100% - 25px);

                    textarea {
                        height: calc(100% - 20px);
                        width: calc(100% - 20px);
                        padding: 10px;
                        border-right: none;
                        border-left: none;
                        border-top: 1px solid #dee2e6;
                        margin: 0px;
                        outline: none;
                        resize: none;
                        border-radius: 0 0 .35rem .35rem;
                    }
                }
            }
        }
    }

    .profile {
        display: flex;
        justify-content: space-between;

        .div-title {
            font-weight: bold;
        }

        .content {
            display: block;
            margin: 20px 0 0 0;
            padding: 0;

            form {
                label {
                    flex-direction: column;
                }
            }

            label {
                display: flex;

                .title {
                    width: 250px;
                }
            }
        }
    }

    .deals {
        .small-text {
            font-size: 12px;
        }

        .agencyInfo {
            font-size: 12px;
            border: 1px solid #ffc00f;
            margin-top: 10px;
            padding: 10px;
            background-color: #fffbf0;
        }
    }

    .seller-address {
        position: relative;
        bottom: -20px;
        font-size: 12px;
        font-style: italic;
        font-weight: bold;
    }

    .image-gallery-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        
        img {
            width: initial!important;
            max-height: 100vh;
            max-width: 100vw;
        }
    }

    .table {
        .seller-short-info {
            display: block;
            overflow: hidden;
            max-height: 70px;
        }
    }
}
